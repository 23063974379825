<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="lg"
      :opacity=".7"
    >
      <ValidationObserver ref="form">
        <Form
          :users-data.sync="formData"
          @formSubmit="doFormSave"
        />
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import _ from 'lodash'
import store from '@/store'
import axiosIns from '@/libs/axios'
// eslint-disable-next-line no-unused-vars
import Form from './Form.vue'

const emptyFormData = Object.freeze({
  name: '',
  email: '',
  password: '',
})

export default {
  name: 'UsersCreate',
  components: {
    BCard,
    Form,
    BOverlay,
  },
  metaInfo: {
    title: 'Создание пользователя',
  },
  data() {
    return {
      formData: _.cloneDeep(emptyFormData),
      usersData: null,
      loading: false,
    }
  },
  mounted() {
    Promise.all([
    ]).then(() => {
      this.loading = false
    })
  },
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
  methods: {
    async doFormSave() {
      this.loading = true

      try {
        const res = await axiosIns({
          method: 'POST',
          url: '/users',
          data: this.formData,
        })
        this.loading = false

        if (res.data.status === 'success') {
          this.$router.push({ name: 'users' })
          this.sendToast({
            title: 'Успех!',
            icon: 'CheckCircleIcon',
            text: 'Пользователь создан.',
            variant: 'success',
          })
        }
      } catch (error) {
        this.loading = false
        if (error.response.status === 422) {
          this.serverErrors = error.response.data.data
          this.$refs.form.setErrors(this.serverErrors)
          this.sendToast({
            title: 'Ошибка!',
            icon: 'AlertCircleIcon',
            text: 'Некоторые поля не заполнены, или заполнены неверно!',
            variant: 'danger',
          })
        }
      }
    },
  },
}
</script>
